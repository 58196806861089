import {
	GET_PARTICIPANT_PROGRAMS,
	GET_PARTICIPANT_PROGRAMS_SUCCESS,
	GET_PARTICIPANT_PROGRAMS_ERROR,
	GET_COACH_PROGRAMS,
	GET_COACH_PROGRAMS_SUCCESS,
	GET_COACH_PROGRAMS_EMPTY,
	GET_COACH_PROGRAMS_ERROR,
	GET_USER_SINGLE_PROGRAM,
	GET_USER_SINGLE_PROGRAM_SUCCESS,
	GET_USER_SINGLE_PROGRAM_ERROR,
	UPDATE_USER_SINGLE_PROGRAM_SESSION,
	CLEAR_PROGRAMS,
	CLEAR_USER_SINGLE_PROGRAM,
	GET_SCHOOL_ADMIN_PROGRAMS,
	GET_SCHOOL_ADMIN_PROGRAMS_SUCCESS,
	GET_SCHOOL_ADMIN_PROGRAMS_ERROR,
	SET_ACTIVE_TOPIC,
	ADD_NEW_ACTION_ITEM,
	DELETE_ACTION_ITEMS,
	UPDATE_ACTION_ITEM,
	UPDATE_ACTION_ITEM_LIST_PROGRAMM,
	UPLOAD_ATTACH_FILE_PROGRAM_TOPIC,
	UPLOAD_ATTACH_FILE_PROGRAM_TOPIC_ACTIVITY,
	UPDATE_UPLOADED_ATTACHE_FILES,
	COMPLETE_ACTIVITY,
	COMPLETE_ACTIVITY_ERROR,
	COMPLETE_ACTIVITY_SUCCESS,
	CLEAR_USER_PROGRAM_ERRORS,
	GET_FILES_DOWNLOAD_ERROR,
	EDIT_TOPIC_DESCRIPTION,
	EDIT_TOPIC_DESCRIPTION_ERROR,
	EDIT_TOPIC_DESCRIPTION_SUCCESS,
	EDIT_ACTIVITY_DESCRIPTION,
	EDIT_ACTIVITY_DESCRIPTION_SUCCESS,
	COMPLETE_SESSION,
	COMPLETE_SESSION_SUCCESS,
	COMPLETE_SESSION_ERROR,
	CREATE_CATEGORY_DESCRIPTION,
	CREATE_CATEGORY_DESCRIPTION_SUCCESS,
	CREATE_CATEGORY_DESCRIPTION_ERROR,
	DELETE_ATTACH_FILE_TOPIC,
	DELETE_ATTACH_FILE_TOPIC_SUCCESS,
	DELETE_ATTACH_FILE_TOPIC_ERROR,
	DELETE_ATTACH_FILE_ACTIVITY,
	DELETE_ATTACH_FILE_ACTIVITY_SUCCESS,
	DELETE_ATTACH_FILE_ACTIVITY_ERROR,
	CLEAR_DELETE_ATTACH_FILE_INFO,
	SESSION_REQUEST,
	SESSION_REQUEST_SUCCESS,
	SESSION_REQUEST_ERROR,
	CLEAR_SESSION_REQUEST_DATA,
	CONTINUE_PROGRAM,
	CONTINUE_PROGRAM_SUCCESS,
	CONTINUE_PROGRAM_ERROR,
} from "../constants";

export function getCoachPrograms(token, params) {
	return {
		type: GET_COACH_PROGRAMS,
		payload: token,
		params,
	};
}

export function getCoachProgramsSuccess(successData) {
	return {
		type: GET_COACH_PROGRAMS_SUCCESS,
		payload: successData,
	};
}

export function getCoachProgramsEmpty(emptyData) {
	return {
		type: GET_COACH_PROGRAMS_EMPTY,
		payload: emptyData,
	};
}

export function getCoachProgramsError(errorData) {
	return {
		type: GET_COACH_PROGRAMS_ERROR,
		payload: errorData,
	};
}

export function getParticipantPrograms(token = "") {
	return {
		type: GET_PARTICIPANT_PROGRAMS,
		payload: token,
	};
}

export function getParticipantProgramsSuccess(successData) {
	return {
		type: GET_PARTICIPANT_PROGRAMS_SUCCESS,
		payload: successData,
	};
}

export function getParticipantProgramsError(errorData) {
	return {
		type: GET_PARTICIPANT_PROGRAMS_ERROR,
		payload: errorData,
	};
}

export function getUserSingleProgram(programId, activityId) {
	return {
		type: GET_USER_SINGLE_PROGRAM,
		payload: { programId, activityId },
	};
}

export function getUserSingleProgramSuccess(successData) {
	return {
		type: GET_USER_SINGLE_PROGRAM_SUCCESS,
		payload: successData,
	};
}

export function getUserSingleProgramError(errorData) {
	return {
		type: GET_USER_SINGLE_PROGRAM_ERROR,
		payload: errorData,
	};
}

export function updateUserSingleProgram(updateData) {
	return {
		type: UPDATE_USER_SINGLE_PROGRAM_SESSION,
		payload: updateData,
	};
}

export function setActiveTopic(topicId) {
	return {
		type: SET_ACTIVE_TOPIC,
		payload: topicId,
	};
}

export function clearSingleProgram() {
	return {
		type: CLEAR_USER_SINGLE_PROGRAM,
	};
}

export function clearPrograms() {
	return {
		type: CLEAR_PROGRAMS,
	};
}

export function getSchoolAdminPrograms(params) {
	return {
		type: GET_SCHOOL_ADMIN_PROGRAMS,
		params,
	};
}

export function getSchoolAdminProgramsSuccess(successData) {
	return {
		type: GET_SCHOOL_ADMIN_PROGRAMS_SUCCESS,
		payload: successData,
	};
}

export function getSchoolAdminProgramsError(errorData) {
	return {
		type: GET_SCHOOL_ADMIN_PROGRAMS_ERROR,
		payload: errorData,
	};
}

export function addNewActivityActionItem(topicActivityID) {
	return {
		type: ADD_NEW_ACTION_ITEM,
		payload: topicActivityID,
	};
}

export function deleteActivityActionItem(topicActivityID, taskID) {
	return {
		type: DELETE_ACTION_ITEMS,
		payload: { topicActivityID, taskID },
	};
}

export function updateActivityActionItem(taskID) {
	return {
		type: UPDATE_ACTION_ITEM,
		payload: taskID,
	};
}

export function updateActionItemUserSingleProgram(updateData) {
	return {
		type: UPDATE_ACTION_ITEM_LIST_PROGRAMM,
		payload: updateData,
	};
}

export function uploadAttachFileProgramTopicSingleProgram(attachFile) {
	return {
		type: UPLOAD_ATTACH_FILE_PROGRAM_TOPIC,
		payload: attachFile,
	};
}

export function uploadAttachFileProgramTopicActivitySingleProgram(attachFile) {
	return {
		type: UPLOAD_ATTACH_FILE_PROGRAM_TOPIC_ACTIVITY,
		payload: attachFile,
	};
}

export function updateAttachedFilesUserSingleProgram(updateData) {
	return {
		type: UPDATE_UPLOADED_ATTACHE_FILES,
		payload: updateData,
	};
}

export function completeActivity(requestData) {
	return {
		type: COMPLETE_ACTIVITY,
		payload: requestData,
	};
}

export function completeActivitySuccess(updatedData) {
	return {
		type: COMPLETE_ACTIVITY_SUCCESS,
		payload: updatedData,
	};
}

export function completeActivityError(errorData) {
	return {
		type: COMPLETE_ACTIVITY_ERROR,
		payload: errorData,
	};
}

export function clearUserProgramErrors() {
	return {
		type: CLEAR_USER_PROGRAM_ERRORS,
	};
}

export function getFilesDownloadError(errorData) {
	return {
		type: GET_FILES_DOWNLOAD_ERROR,
		payload: errorData,
	};
}

export function editTopicDescription(programId, topicId, topicDescription) {
	return {
		type: EDIT_TOPIC_DESCRIPTION,
		payload: {
			id: programId,
			topic_id: topicId,
			description: topicDescription,
		},
	};
}

export function editTopicDescriptionSuccess(updatedTopic) {
	return {
		type: EDIT_TOPIC_DESCRIPTION_SUCCESS,
		payload: updatedTopic,
	};
}

export function editTopicDescriptionError(errorData) {
	return {
		type: EDIT_TOPIC_DESCRIPTION_ERROR,
		payload: errorData,
	};
}

export function createCategoryDescription(
	activityId,
	categoryId,
	description,
	existingCategories
) {
	return {
		type: CREATE_CATEGORY_DESCRIPTION,
		payload: {
			id: activityId,
			category_id: categoryId,
			description: description,
			existingCategories: existingCategories,
		},
	};
}

export function createCategoryDescriptionSuccess(updatedCategories) {
	return {
		type: CREATE_CATEGORY_DESCRIPTION_SUCCESS,
		payload: updatedCategories,
	};
}

export function createCategoryDescriptionError(errorData) {
	return {
		type: CREATE_CATEGORY_DESCRIPTION_ERROR,
		payload: errorData,
	};
}

export function editActivityDescription(
	programId,
	activityId,
	activityDescription
) {
	return {
		type: EDIT_ACTIVITY_DESCRIPTION,
		payload: {
			id: programId,
			activity_id: activityId,
			details: activityDescription,
		},
	};
}

export function editActivityDescriptionSuccess(updatedTopic) {
	return {
		type: EDIT_ACTIVITY_DESCRIPTION_SUCCESS,
		payload: updatedTopic,
	};
}

export function editActivityDescriptionError(errorData) {
	return {
		type: EDIT_TOPIC_DESCRIPTION_ERROR,
		payload: errorData,
	};
}

export function completeSession(sessionId) {
	return {
		type: COMPLETE_SESSION,
		payload: sessionId,
	};
}

export function completeSessionSuccess(completedSession) {
	return {
		type: COMPLETE_SESSION_SUCCESS,
		payload: completedSession,
	};
}

export function completeSessionError(errorData) {
	return {
		type: COMPLETE_SESSION_ERROR,
		payload: errorData,
	};
}

export function deleteAttachTopicFile(fileId, file) {
	return {
		type: DELETE_ATTACH_FILE_TOPIC,
		payload: {
			fileId: fileId,
			files: [file],
		},
	};
}

export function deleteAttachTopicFileSuccess(deletedFile) {
	return {
		type: DELETE_ATTACH_FILE_TOPIC_SUCCESS,
		payload: deletedFile,
	};
}

export function deleteAttachTopicFileError(errorData) {
	return {
		type: DELETE_ATTACH_FILE_TOPIC_ERROR,
		payload: errorData,
	};
}

export function deleteAttachActivityFile(fileId, file) {
	return {
		type: DELETE_ATTACH_FILE_ACTIVITY,
		payload: {
			fileId: fileId,
			files: [file],
		},
	};
}

export function deleteAttachActivityFileSuccess(deletedFile) {
	return {
		type: DELETE_ATTACH_FILE_ACTIVITY_SUCCESS,
		payload: deletedFile,
	};
}

export function deleteAttachActivityFileError(errorData) {
	return {
		type: DELETE_ATTACH_FILE_ACTIVITY_ERROR,
		payload: errorData,
	};
}

export function clearDeleteFileInfo() {
	return {
		type: CLEAR_DELETE_ATTACH_FILE_INFO,
	};
}

export function sessionRequest(programId, description) {
	return {
		type: SESSION_REQUEST,
		payload: { programId, description },
	};
}

export function sessionRequestSuccess(programId) {
	return {
		type: SESSION_REQUEST_SUCCESS,
		payload: programId,
	};
}

export function sessionRequestError(errorData) {
	return {
		type: SESSION_REQUEST_ERROR,
		payload: errorData,
	};
}

export function sessionRequestClearData(errorData) {
	return {
		type: CLEAR_SESSION_REQUEST_DATA,
		payload: errorData,
	};
}

export function continueProgramDecision(
	programId,
	programTopicId,
	decisionBox,
	callback
) {
	return {
		type: CONTINUE_PROGRAM,
		payload: {
			programId,
			programTopicId,
			decisionBox,
			callback,
		},
	};
}

export function continueProgramDecisionSuccess() {
	return {
		type: CONTINUE_PROGRAM_SUCCESS,
	};
}

export function continueProgramDecisionError(errorData) {
	return {
		type: CONTINUE_PROGRAM_ERROR,
		payload: errorData,
	};
}
