import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner/Spinner";
import Scrollbar from "react-scrollbars-custom";
import { getProgramsTodos } from "../../../../../redux/actions";
import EmptyData from "../EmptyData/EmptyData";
import TodoItem from "./TodoItem/TodoItem";
import "./ActionItems.scss";

const ActionItems = () => {
	const dispatch = useDispatch();
	const { programsTodos, programsTodosLoading } = useSelector(
		(state) => state.participantDashboard
	);

	useEffect(() => {
		dispatch(getProgramsTodos());
	}, []);

	return (
		<div className="action-items">
			<div className="action-items__title">Action items</div>
			<div className="action-items__list">
				{programsTodosLoading ? (
					<Spinner size={50} />
				) : (
					<Scrollbar
						className="custom-scroll__wrapper"
						trackYElement={null}
						translateContentSizesToHolder
						disableTracksWidthCompensation
					>
						{programsTodos?.length > 0 ? (
							programsTodos?.map((item) => (
								<TodoItem
									key={item.id}
									program={item.participant_program}
									name={item.name}
									todos={item.todos}
									activityId={item.id}
								/>
							))
						) : (
							<EmptyData type="todo" />
						)}
					</Scrollbar>
				)}
			</div>
		</div>
	);
};

export default ActionItems;
